<template>
  <main class="page_bg_grey tourism-profit-percent blank-aside-js" :class = "(isOpenAside=='true')?'blank-aside':''">
    <p class="main-title weight-700">{{ $t("ASIDE.CONTRACT_DATA") }}</p>
    <div class="page-content">
      <DRFilter @changeBusiness="changeBusiness" ref="drFilter_comp"></DRFilter>
      <div class="reseller-safe__container">
        <div class="reseller-safe__cover reseller-safe__cover-1 px10">
          <div class="p-relative payment-method ">
            <label class="label">{{
              $t("COMMON.PAYMENT_METHOD")
            }}</label>
            <vue-select
              :options="mainContent.paymentMethod[currentLang]"
              class="input cus-select"
              v-model="mainContent.paymentMethod.selected"
              @input="changePaymentMethod"
            ></vue-select>
          </div>
          <div class="m-t-20">
            <label for="" class="label">
              {{ $t("REPORT_SALES.CLIENT_NO") }}
            </label>
            <input
              class="input"
              type="text"
              v-model="mainContent.business_identifier"
            />
          </div>
          <div class="d-flex align-items-center m-t-20">
            <v-checkbox color="#0D3856" class="cus-checkbox" v-model="mainContent.use_point"></v-checkbox>
            <label class="px10">{{ $t("MANAGEMENT.POINTS_USER") }}</label>
          </div>
          <div class="reseller-safe__days">
            <p class="text reseller-safe__days-text">
              {{ $t("REGISTER_RESELLER.COLLECTION_DAYS") }}:
            </p>
            <div class="reseller-safe__days-wrapper">
              <span 
                class="reseller-safe__day"
                :class="mainContent.monday ? 'reseller-safe__day_active' : ''"
                @click="activeDay('monday')"
              >
                {{ $t("REGISTER_RESELLER.A") }}
              </span>
              <span 
                class="reseller-safe__day"
                :class="mainContent.tuesday ? 'reseller-safe__day_active' : ''"
                @click="activeDay('tuesday')"
              >
                {{ $t("REGISTER_RESELLER.B") }}
              </span>
              <span 
                class="reseller-safe__day"
                :class="mainContent.wednesday ? 'reseller-safe__day_active' : ''"
                @click="activeDay('wednesday')"
              >
                {{ $t("REGISTER_RESELLER.C") }}
              </span>
              <span 
                class="reseller-safe__day"
                :class="mainContent.thursday ? 'reseller-safe__day_active' : ''"
                @click="activeDay('thursday')"
              >
                {{ $t("REGISTER_RESELLER.D") }}
              </span>
              <span 
                class="reseller-safe__day"
                :class="mainContent.friday ? 'reseller-safe__day_active' : ''"
                @click="activeDay('friday')"
              >
                {{ $t("REGISTER_RESELLER.E") }}
              </span>
              <span 
                class="reseller-safe__day"
                :class="mainContent.saturday ? 'reseller-safe__day_active' : ''"
                @click="activeDay('saturday')"
              >
                {{ $t("REGISTER_RESELLER.F") }}
              </span>
            </div>
          </div>
        </div>

        <div class="reseller-safe__cover reseller-safe__cover-2 px10">
          <p class="text reseller-safe__bank-text">
            {{ $t("REGISTER_RESELLER.BANK_ACCOUNT_INFORMATION") }}
          </p>

          <div class="reseller-safe__bank">
            <div class="reseller-safe__bank-name reseller-safe__bank-item">
              <label class="label reseller-safe__label">{{
                $t("REGISTER_RESELLER.BANK")
              }}</label>
              <input class="input reseller-safe__input" type="text" />
            </div>
            <div class="reseller-safe__bank-number reseller-safe__bank-item">
              <label class="label reseller-safe__label">{{
                $t("REGISTER_RESELLER.BANK_NUMBER")
              }}</label>
              <input class="input reseller-safe__input" type="text" />
            </div>
            <div class="reseller-safe__bank-account reseller-safe__bank-item">
              <label class="label reseller-safe__label">{{
                $t("REGISTER_RESELLER.ACCOUNT_NUMBER")
              }}</label>
              <input class="input reseller-safe__input" type="text" />
            </div>
            <div class="reseller-safe__bank-branch reseller-safe__bank-item">
              <label class="label reseller-safe__label">{{
                $t("REGISTER_RESELLER.BRANCH")
              }}</label>
              <input class="input reseller-safe__input" type="text" />
            </div>
          </div>
        </div>

        <div class="d-flex flex-wrap align-items-end m-t-20">
          <div class="px10 small-input">
            <label class="label">{{
              $t("REGISTER_RESELLER.LINE_OF_CREDIT")
            }}</label>
            <input class="input" 
              type="text" 
              @keypress="isNumber($event)" 
              v-model="mainContent.frame"
            />
          </div>
          <div class="px10 small-input">
            <label class="label">{{
              $t("RESELLER_DETAILS.TEMPORARY_FRAMEWORK")
            }}</label>
            <input 
              class="input" 
              type="text" 
              @keypress="isNumber($event)" 
              v-model="mainContent.temp_frame"/>
          </div>
          <div class="px10 small-input">
            <label class="label">{{ $t("COMMON.BALANCE") }}</label>
            <input
              class="input color-red1 bg-grey"
              type="text"
              v-model="mainContent.balance"
              @keypress="isNumber($event)"
            />
          </div>
        </div>
        <div class="px10 small-input m-t-20">
          <button
            class="primary-btn payment_btn"
            @click="openUpdateBalanceModal()"
          >
            {{ $t("TOURISM_REPORT.PAYMENT") }}
          </button>
        </div>
        <div class="px10 small-input m-t-20">
          <label class="label">{{
            $t("RESELLER_DETAILS.BALANCE_FOR_UTILIZATION")
          }}</label>
          <input
            class="input bg-grey"
            type="text"
            v-model="mainContent.utilization"
            @keypress="isNumber($event)"
          />
        </div>

        <div class="reseller-safe__comment px10">
          <label class="label">
            {{ $t("REGISTER_RESELLER.REMARKS") }}
          </label>
          <textarea class="reseller-safe__textarea bg-white"></textarea>
        </div>
        <div class="d-flex">
          <div class="px10 small-input m-t-20">
            <button class="primary-btn payment_btn" @click="saveData()">
              {{ $t("COMMON.SAVE") }}
            </button>
          </div>
          <div class="px10 small-input m-t-20" v-if="currentData.status==2">
            <button class="primary-btn payment_btn" @click="cancelFreezeUnlockData()">
              {{ $t("MANAGEMENT.CANCELLATION_FREEZE") }}
            </button>
          </div>
          <div class="px10 small-input m-t-20" v-else>
            <button class="primary-btn payment_btn" @click="freezeData()">
              {{ $t("BUSINESS_DETAILS.FREEZE") }}
            </button>
          </div>
          <div class="px10 small-input m-t-20" v-if="currentData.status==3">
            <button class="primary-btn payment_btn" @click="cancelFreezeUnlockData()">
              {{ $t("MANAGEMENT.UNLOCK") }}
            </button>
          </div>
          <div class="px10 small-input m-t-20" v-else>
            <button class="primary-btn payment_btn" @click="lockData()">
              {{ $t("MANAGEMENT.LOCK") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- ----- update balance ----------- -->
    <modal
      :width="900"
      :adaptive="true"
      class="updateBalanceModal CEModal modal-scroll-bar"
      name="updateBalanceModal"
    >
      <!-- <button
        class="button modal-changeHotel__close"
        @click="closeUpdateBalanceModal()"
      >
        <img src="/assets/img/close.svg" alt />
      </button> -->

      <h4>{{ $t("RESELLER_DETAILS.BALANCE_UPDATE") }}</h4>
      <div class="d-flex flex-wrap align-items-center justify-content-between m-t-20">
        <div class="reseller-safe__bank-name reseller-safe__bank-item">
          <label class="label reseller-safe__label">
            {{ $t("RESELLER_DETAILS.SUM") }}
          </label>
          <input 
            class="input reseller-safe__input" 
            type="text" 
            v-model="balanceModal.sum"/>
        </div>
        <div class="reseller-safe__bank-number reseller-safe__bank-item">
          <label class="label reseller-safe__label">
            {{ $t("PAYMENT_REPORT.RECEPTION_NUMBER") }}
          </label>
          <input 
            class="input reseller-safe__input" 
            type="text" 
            v-model="balanceModal.invoiceNumber"/>
        </div>
        <div class="p-relative payment-method">
          <label class="label">{{
            $t("RESELLER_DETAILS.PAYMENT_METHODS")
          }}</label>
          <vue-select
            :options="balanceModal.paymentTypes.options"
            class="input cus-select"
            v-model="balanceModal.paymentTypes.selected"
          ></vue-select>
        </div>
      </div>
      <div class="m-t-20">
        <label class="reseller-safe__label reseller-safe__comment-label">
          {{ $t("REGISTER_RESELLER.REMARKS") }}
        </label>
        <textarea 
          class="reseller-safe__textarea bg-white"
          v-model="balanceModal.note"
          ></textarea>
      </div>
      <div class="d-flex flex-wrap align-items-center justify-content-center m-t-30">
        <button 
          class="primary-btn"
          @click="updateBalance()"
        >
          {{ $t("RESELLER_DETAILS.UPDATE_BALANCE") }}
        </button>
        <button 
          class="primary-btn bg-red"
          @click="closeUpdateBalanceModal()"
        >
          {{ $t("SUPPLIER_PAYMENT.CANCELATION") }}
        </button>
      </div>
    </modal>
    <loading
      :active.sync="showLoadingAnimation"
      :can-cancel="false"
      :is-full-page="true"
    ></loading>
  </main>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import DRFilter from "../../../components/management/DRFilter.vue";

export default {
  name: "DRContractDetails",
  components: {
    Loading,
    DRFilter
  },
  computed: {
    ...mapState({
      paymentTypesData: state => state.reseller_details.paymentTypes,
      businessData: (state) => state.seller_tourism_percentage.businessData,
      loading1: (state) => state.seller_tourism_percentage.loading,
      loading2: (state) => state.reseller_details.loading,
    }),
    showLoadingAnimation() {
      return this.loading1 || this.loading2;
    },
  },
  async created() {
    if(this.paymentTypesData == "") {
      await this.getPaymentTypes();
    }
    if(this.paymentTypesData.length != 0) {
      this.setPaymentTypesOption();
    }
  },
  data() {
    return {
      businessID: "",
      distributeID: "",
      currentData: "",
      mainContent: {
        balance: "",
        temp_frame: "",
        frame: "",
        utilization: "",
        businessId: "",
        phoneNumber: "",
        businessLicense: "",
        monday: "",
        tuesday: "",
        wednesday: "",
        thursday: "",
        friday: "",
        saturday: "",
        sunday: "",
        paymentMethod: {
          selected: {label: "", code: ""},
          he: [
            {label: "הוראת קבע", code: "הוראת קבע"},
            {label: "תשלום לסוכן", code: "תשלום לסוכן"},
            {label: "תשלום מראש", code: "תשלום מראש"}
          ],
          en: [
            {label: "Standing Order", code: "הוראת קבע"},
            {label: "Payment to Agent", code: "תשלום לסוכן"},
            {label: "Prepaid", code: "תשלום מראש"}
          ],
          ar: [
            {label: "הוראת קבע בערבית", code: "הוראת קבע"},
            {label: "תשלום לסוכן בערבית", code: "תשלום לסוכן"},
            {label: "תשלום מראש בערבית", code: "תשלום מראש"}
          ]
        }
      },
      balanceModal: {
        paymentTypes: {
          selected: { label: "", code: "" },
          options: []
        },
        sum: "",
        invoiceNumber: "",
        note: ""
      }
    };
  },
  methods: {
    ...mapActions("seller_tourism_percentage", {
      updateBusinessData: "updateBusinessData"    
    }),
    ...mapActions("reseller_details", {
      getPaymentTypes: "getPaymentTypes",
      update_balance: "update_balance",
      filterSellerPercentage: "filterSellerPercentage"
    }),
    async changeBusiness(data) {
      this.businessID = data.business_id;
      this.distributeID = data.distribute_id;
      this.currentData = this.businessData.filter(function (el) {
        return el.id == data.business_id;
      })[0];
      this.setResellerDetails();
    },
    setPaymentTypesOption() {
      for (const key in this.paymentTypesData) {
        if (this.paymentTypesData[key]) {
            let paymentTypes_label = this.paymentTypesData[key]['name_' + this.currentLang];
            let paymentTypes_code = this.paymentTypesData[key].id;
            this.balanceModal.paymentTypes.options.push({ "label": paymentTypes_label, "code": paymentTypes_code });
          }
      }
      this.balanceModal.paymentTypes.selected = this.balanceModal.paymentTypes.options[0];
    },
    setResellerDetails() {
      this.mainContent.balance = this.currentData.balance;
      this.mainContent.business_identifier = this.currentData.business_identifier;
      this.mainContent.use_point = this.currentData.use_point;
      this.mainContent.temp_frame = this.currentData.temp_frame;
      this.mainContent.frame = this.currentData.frame;
      this.mainContent.utilization = this.currentData.balance + this.currentData.temp_frame + this.currentData.frame;
      this.mainContent.businessId = this.currentData.business_identifier;
      this.mainContent.phoneNumber = this.currentData.phone_number;
      this.mainContent.businessLicense = this.currentData.business_license_number;
      this.mainContent.monday = this.currentData.monday;
      this.mainContent.tuesday = this.currentData.tuesday;
      this.mainContent.wednesday = this.currentData.wednesday;
      this.mainContent.thursday = this.currentData.thursday;
      this.mainContent.friday = this.currentData.friday;
      this.mainContent.saturday = this.currentData.saturday;
      this.mainContent.sunday = this.currentData.sunday;
      this.mainContent.paymentMethod.selected.code = this.currentData.payment_method;
      for (const key in this.mainContent.paymentMethod[this.currentLang]) {
        if (this.mainContent.paymentMethod[this.currentLang][key].code == this.currentData.payment_method) {
          this.mainContent.paymentMethod.selected.label = this.mainContent.paymentMethod[this.currentLang][key].label
        }
      }
    },
    async updateBalance() {
      let params = {
        business_id: this.currentData.id,
        invoice_number: this.balanceModal.invoiceNumber,
        note: this.balanceModal.note,
        sum: this.balanceModal.sum,
        payment_type: this.balanceModal.paymentTypes.selected.code,
      }
      await this.update_balance({'params': params});
      this.closeUpdateBalanceModal();
    },
    changePaymentMethod() {},
    
    activeDay(day) {
      this.mainContent[day] = !this.mainContent[day];
    },

    async saveData() {
      const params = {
        balance: this.mainContent.balance,
        business_identifier: this.mainContent.businessId,
        frame: this.mainContent.frame,
        friday: this.mainContent.friday,
        monday: this.mainContent.monday,
        payment_method: this.mainContent.paymentMethod.selected.code,
        sunday: this.mainContent.sunday,
        temp_frame: this.mainContent.temp_frame,
        thursday: this.mainContent.thursday,
        tuesday: this.mainContent.tuesday,
        wednesday: this.mainContent.wednesday,
        business_identifier: this.mainContent.business_identifier,
        use_point: this.mainContent.use_point,
      };
      const id = this.currentData.id;
      await this.updateBusinessData({
        id: id,
        params: params,
      });
      this.$refs.drFilter_comp.updateBusinessOption();
    },
    async freezeData() {
      const params = {
        status: 2,
      };
      const id = this.currentData.id;
      await this.updateBusinessData({
        id: id,
        params: params,
      });
      this.$refs.drFilter_comp.updateBusinessOption();
    },
    async cancelFreezeUnlockData() {
      const params = {
        status: 1
      };
      const id = this.currentData.id;
      await this.updateBusinessData({
        id: id,
        params: params,
      });
      this.$refs.drFilter_comp.updateBusinessOption();
    },
    async lockData() {
      const params = {
        status: 3,
      };
      const id = this.currentData.id;
      await this.updateBusinessData({
        id: id,
        params: params,
      });
      this.$refs.drFilter_comp.updateBusinessOption();
    },

    /* <!-- ------------update balance modal-------------- --> */
    openUpdateBalanceModal() {
      this.$modal.show("updateBalanceModal");
    },
    closeUpdateBalanceModal() {
      this.$modal.hide("updateBalanceModal");
    },
  },
};
</script>

<style lang="scss">
.page-content {
  margin: 30px 30px 50px;
  @media (max-width: 767px) {
    margin: 30px 0 50px;
  }
}
.tourism-profit-percent {
  label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .small-input {
    max-width: 210px;
  }
  .cus-select {
    min-width: 230px;
    padding: 0 7px;
    .vs__selected {
      font-size: 17px;
      color: #002036;
      font-weight: 300;
      height: 80%;
      margin: 2px;
      margin-top: 4px;
    }
  }
}
.updateBalanceModal {
  .vm--modal {
    background-color: #f3f3f3;
    padding: 20px 60px;
    .reseller-safe__bank-item {
      max-width: 200px;
    }
    .payment-method {
      width: 100%;
      max-width: 300px;
    }
    .primary-btn {
      max-width: 180px;
      margin: 0 20px 10px 20px;
    }
    .reseller-safe__bank-item {
      margin-top: 0 !important;
    }
  }
  .vs__dropdown-menu {
    max-height: 250px;
  }
}
</style>

<style lang="scss" scoped>
.v-application p {
  margin-bottom: unset;
}
.reseller-safe__container {
  margin: 0;
  margin-top: 40px;
  @media (max-width: 1365px) {
    padding: 0;
  }
  .payment-method {
    margin-top: 37px;
  }
  .payment_btn {
    min-width: 180px;
    margin-top: 29px;
  }
}
.reseller-safe__label {
  margin-bottom: 0;
}
</style>