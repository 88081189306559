<template>
  <div class="d-flex align-items-center m-b-30 flex-wrap">
    <div class="px10 m-b-10 form-item dr-filter-dropdown">
      <label>{{ $t('TOURISM_PROFIT_PERCENTAGES.DISTRIBUTOR') }}</label>
      <vue-select
        :options="distribute.options"
        class="input cus-select"
        v-model="distribute.selected"
        @input="changeDistribute"
      ></vue-select>
    </div>
    <div class="px10 m-b-10 form-item dr-filter-dropdown">
      <label>{{ $t('TOURISM_PROFIT_PERCENTAGES.MARKETER') }}</label>
      <vue-select
        :options="business.options"
        class="input cus-select"
        v-model="business.selected"
        @input="changeBusiness"
      ></vue-select>
    </div>
    <div class="px10 m-b-10 form-item">
      <label>{{ $t('TOURISM_PROFIT_PERCENTAGES.CLIENT_NO') }}</label>
      <input
        type="text"
        class="input"
        :placeholder="
          $t('TOURISM_PROFIT_PERCENTAGES.SEARCH_CUSTOMER_NUMBER')
        "
        v-model="client_no"
        :disabled="!!mobile_phone.length || !!hp_dealer.length"
        @keypress="isNumber($event)"
      />
    </div>
    <div class="px10 m-b-10 form-item">
      <label>{{ $t('TOURISM_PROFIT_PERCENTAGES.MOBILE_PHONE') }}</label>
      <input
        type="text"
        class="input"
        :placeholder="
          $t('TOURISM_PROFIT_PERCENTAGES.MOBILE_PHONE_SEARCHING')
        "
        v-model="mobile_phone"
        :disabled="!!client_no.length || !!hp_dealer.length"
        @keypress="isNumber($event)"
      />
    </div>
    <div class="px10 m-b-10 form-item">
      <label>{{ $t('TOURISM_PROFIT_PERCENTAGES.HP_AUTHORIZED_DEALER') }}</label>
      <input
        type="text"
        class="input"
        v-model="hp_dealer"
        :disabled="!!client_no.length || !!mobile_phone.length"
        @keypress="isNumber($event)"
      />
    </div>
    <div class="px10 m-b-10 form-item filter-btn">
      <label></label>
      <button class="button filter__button" @click="filter()"></button>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'DRFilter',
  data() {
    return {
      distribute: {
        options: [],
        selected: ""
      },
      business: {
        options: [],
        selected: ""
      },
      client_no: '',
      mobile_phone: '',
      hp_dealer: '',
      createdSeller: ''
    };
  },
  computed: {
    ...mapState({
      distributeData: (state) => state.seller_tourism_percentage.distributeData,
      businessData: (state) => state.seller_tourism_percentage.businessData,
    }),
    
  },
  async created() {
    if (this.distributeData == '') {
      await this.getDistributes();
    }
    this.createDistributeDropdown();
   
  },
  methods: {
    ...mapActions('seller_tourism_percentage', {
      getDistributes: 'getDistributes',
      getBusiness: 'getBusiness'
    }),
    ...mapActions("alert", {
      errorAlert: "error"
    }),
    storageBusinessId() {
      return localStorage.getItem("resellerBusinessId");
    },
    storageDistributeId() {
      return localStorage.getItem("resellerDistributeId");
    },
    createDistributeDropdown() {
      if (this.distributeData.length != 0) {
        for (const key in this.distributeData) {
          if (this.distributeData[key]) {
            let distribute_label = this.distributeData[key].name;
            let distribute_code = this.distributeData[key].distributor_id;
            this.distribute.options.push({
              label: distribute_label,
              code: distribute_code,
            });
          }
        }
        const storageDistributeId = this.storageDistributeId();
        if (storageDistributeId != "undefined") {
          const distributeTemp = this.distribute.options.find((el) => {
            return el.code == storageDistributeId;
          });
          if (distributeTemp) {
            this.distribute.selected = distributeTemp;
          } else {
            this.distribute.selected = this.distribute.options[0];
          }
        } else {
          this.distribute.selected = this.distribute.options[0];
        }
        this.updateBusinessOption();
      }
    },
    async updateBusinessOption(newSeller=false) {
      await this.getBusiness({ id: this.distribute.selected.code });
      this.business = {
        options: [],
        selected: ''
      }
      if (this.businessData.length != 0) {
        localStorage.setItem("resellerDistributeId", this.distribute.selected.code);
        for (const key in this.businessData) {
          if (this.businessData[key]) {
            let business_label = this.businessData[key].name;
            let business_code = this.businessData[key].id;
            this.business.options.push({
              label: business_label,
              code: business_code,
            });
          }
        }

        if (newSeller) {
          const businessTemp = this.business.options.find((el) => {
            return el.code == this.createdSeller;
          });
          if (businessTemp) {
            this.business.selected = businessTemp;
          } else {
            this.business.selected = this.business.options[0];
          }
        } else {
          const storageBusinessId = this.storageBusinessId();
          if (storageBusinessId != "undefined") {
            const businessTemp = this.business.options.find((el) => {
              return el.code == storageBusinessId;
            });
            if (businessTemp) {
              this.business.selected = businessTemp;
            } else {
              this.business.selected = this.business.options[0];
            }
          } else {
            this.business.selected = this.business.options[0];
          }
        }
        
        this.changeBusiness();
      }
    },
    changeDistribute() {
      this.updateBusinessOption();
    },
    changeBusiness() {
      const id = this.business.selected.code;
      const distribute_id = this.distribute.selected.code;
      const selectedDistribute = this.distributeData.find((el)=> {
        return el.distributor_id == distribute_id;
      });
      const section_20 = selectedDistribute.section_20; 
      localStorage.setItem("resellerBusinessId", id);
      this.$emit('changeBusiness', {
        business_id: id,
        distribute_id: distribute_id,
        section_20: section_20
      });
    },
    changeBusinessSelected(newBusiness) {
      if (newBusiness) {
        this.business.selected = this.business.options.find((el)=> {
          return el.code == newBusiness.id
        });
        this.changeBusiness();
      } else {
        this.errorAlert( this.$t("COMMON.NO_DATA") )
      }
    },
    filter() {
      if (this.client_no.length != 0) {
        const newBusiness = this.businessData.find((el)=> {
          return el.business_identifier == this.client_no
        });
        this.changeBusinessSelected(newBusiness);
        this.client_no = '';
      } else if (this.hp_dealer.length != 0) {
        const newBusiness = this.businessData.find((el)=> {
          return el.business_license_number == this.hp_dealer
        });
        this.changeBusinessSelected(newBusiness);
        this.hp_dealer = '';
      } else if (this.mobile_phone.length != 0) {
        const newBusiness = this.businessData.find((el)=> {
          return el.phone_number == this.mobile_phone
        });
        this.changeBusinessSelected(newBusiness);
        this.mobile_phone = '';
      } 
    },
    updateBusineOptionAfterCreateReseller(id) {
      this.createdSeller = id;
      this.updateBusinessOption(true);
    }
  },
  // async created() {
  //   if (this.distributeData == '') {
  //     await this.getDistributes();
  //   }
  //   if (this.distributeData.length != 0) {
  //     for (const key in this.distributeData) {
  //       if (this.distributeData[key]) {
  //         let distribute_label = this.distributeData[key].name;
  //         let distribute_code = this.distributeData[key].distributor_id;
  //         this.distribute.options.push({
  //           label: distribute_label,
  //           code: distribute_code,
  //         });
  //       }
  //     }
  //     this.distribute.selected = this.distribute.options[0];
  //     this.updateBusinessOption();
  //   }
  // }
};
</script>

<style lang="scss">
.cus-select ul {
  padding-left: 0;
}
</style>

<style lang="scss" scoped>
label {
  display: block;
  height: 24px;
}

.input:disabled {
  background-color: #d7d7d7;
}

.dr-filter-dropdown {
  width: 240px;
}
.form-item {
  width: 240px;
  &.filter-btn {
    width: auto;
  }
}
@media screen and (max-width: 767px) {
  .form-item {
    width: 100%;
  }
}
</style>